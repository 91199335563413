<template>
        <component :is="page" :name="name"></component>
</template>

<script>
import tariffClientBase from '@/views/default/tariff/tariff/clientBase/index.vue'
import tariffClientWork from '@/views/default/tariff/tariff/clientWork/index.vue'
import tariffClientFullList from '@/views/default/tariff/tariff/clientFullList/index.vue'
import tariffClientTemplate from '@/views/default/tariff/type/index.vue'
import tariffClientArchive from '@/views/default/tariff/tariff/clientArchive/index.vue'
    export default {
        components: {
            tariffClientBase,
            tariffClientWork,
            tariffClientFullList,
            tariffClientTemplate,
            tariffClientArchive
        },
        data() {
            return {
                page: 'tariff-client-base',
                name: 'base-tariffs',
                showPreloader: false
            }
        },
        computed: {
            fetchingNewData(){
                return this.$store.state.refresh.fetchingNewData
            }
        },
        watch: {
            fetchingNewData(val){
                console.log('wfewef');
                if (val) {
                    let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                    this.page = param.component
                    this.name = param.name
                }
            }
        }
    }
</script>